import {
    getSingle,
    getCollection,
    strapiRegister,
    strapiLogin,
    getMe,
    strapiUpdate,
    strapiDeleteUser,
    strapiConfirmEmail,
    strapiRecoverPassword,
    strapiResetPassword
} from './strapi'

import {
    ITEM_LOADED,
    COLLECTION_LOADED,
    GLOBALS_LOADED,
    REGISTRATION_SUCC,
    REGISTRATION_FAIL,
    LOGIN,
    LOGIN_FAIL,
    LOGOUT,
    SET_LAYOUT,
    UPDATE_USER,
    PLAY,
    PW_RESET,
    MAIL_SENT
} from './action-types'

export const loadGlobals = () => {
    // console.log('load globals')
    return dispatch => {
        getSingle("global")
            .then(res => {
                res.data.type = "global"
                dispatch({ type: GLOBALS_LOADED, payload: res.data })
            })
            .catch(error => console.error('Error when loading global items: ', error))
    }
}


export const loadSingle = (type) => {
    // console.log('load single')
    return dispatch => {
        getSingle(type)
            .then(res => {
                res.data.type = type
                dispatch({ type: ITEM_LOADED, payload: res.data })
            })
            .catch(error => console.error('Error when loading single type: ', error))
    }
}

export const loadCollection = (collection, filters) => (dispatch) => Promise.resolve().then(() => {
    // console.log('load collection')
    return getCollection(collection, filters)
        .then(res => {
            dispatch({ type: COLLECTION_LOADED, payload: res.data })
        })
        .catch(error => console.error('Error when loading collection: ', error))
})

export const loadCollectionItem = (collection, filters) => {
    // console.log('load collection item')
    return dispatch => {
        getCollection(collection, filters)
            .then(res => {
                res.data = res.data[0]
                res.data.type = collection
                dispatch({ type: ITEM_LOADED, payload: res.data })
            })
            .catch(error => console.error('Error when loading collection item: ', error))
    }
}

export const registerUser = (userdata) => (dispatch) => Promise.resolve().then(() => {
    // console.log("action register")
    // console.log(userdata)
    if (userdata.password.length < 8) {
        return dispatch({ type: REGISTRATION_FAIL, payload: "Password too short" })
    }
    if (userdata.password !== userdata.confirm) {
        return dispatch({ type: REGISTRATION_FAIL, payload: "Passwords don't match" })
    } else {
        // delete userdata.confirm
        userdata.username = userdata.email
        return strapiRegister(userdata)
            .then(res => {
                strapiConfirmEmail(userdata.email)
                    .then(response => {
                        // Handle success.
                        // console.log('Your user received an email');
                    })
                    .catch(error => {
                        // Handle error.
                        console.error('An error occured:', error.response);
                    });
                dispatch({ type: REGISTRATION_SUCC, payload: res.statusText })
                res.data.remember = true
                dispatch({ type: LOGIN, payload: res.data })
            })
            .catch(error => {
                console.error('Error when registering: ', error.response.data)
                dispatch({ type: REGISTRATION_FAIL, payload: error.response.data.message[0].messages[0].message })
            })
    }
})

export const loginUser = (credentials) => (dispatch) => Promise.resolve().then(() => {
    // console.log("login user")
    credentials.identifier = credentials.email
    return strapiLogin(credentials)
        .then(res => {
            if (credentials.remember) {
                localStorage.setItem("artvisit_jwt", res.data.jwt)
            }
            res.data.userdata = res.data.user
            dispatch({ type: LOGIN, payload: res.data })
        })
        .catch(error => {
            console.error('Error when logging in: ', error.response.data.message[0].messages[0].message)
            dispatch({ type: LOGIN_FAIL, payload: error.response.data.message[0].messages[0].message })
        })
})

export const persistLogin = () => (dispatch) => Promise.resolve().then(() => {
    // console.log("persist login")
    let user = {
        jwt: "",
        userdata: {}
    }
    user.jwt = localStorage.artvisit_jwt
    if (user.jwt) {
        return getMe()
            .then(res => {
                Object.assign(user, { userdata: res.data })
                dispatch({ type: LOGIN, payload: user })
            })
    } else return
})

export const logoutUser = () => (dispatch) => Promise.resolve().then(() => {
    // console.log("logout")
    localStorage.clear("artvisit_token")
    return dispatch({ type: LOGOUT, payload: null })
})

export const deleteUser = (userdata) => (dispatch) => Promise.resolve().then(() => {
    // console.log("delete")
    return strapiDeleteUser(userdata)
        .then(res => {
            localStorage.clear("artvisit_token")
            dispatch({ type: LOGOUT, payload: null })
        })
})

export const updateUser = (userdata) => (dispatch) => Promise.resolve().then(() => {
    // console.log("set userdata")
    return strapiUpdate(userdata)
        .then(res => {
            dispatch({ type: UPDATE_USER, payload: res.user })
        })
        .catch(error => {
            // console.log(error.response)
            // console.error('Error: ', error.response.data.message[0].messages[0].message)
        })
})

export const setItem = (item, type) => {
    // console.log("set item")
    item.type = type
    return dispatch => {
        dispatch({ type: ITEM_LOADED, payload: item })
    }
}

export const setLayout = (layout) => {
    // console.log("set layout")
    return dispatch => {
        dispatch({ type: SET_LAYOUT, payload: layout })
    }
}

export const play = (playing) => {
    return dispatch => {
        dispatch({ type: PLAY, payload: playing })
    }
}

export const recoverPassword = (email) => (dispatch) => Promise.resolve().then(() => {
    return strapiRecoverPassword(email)
        .then(res => {
            // console.log(res)
            // console.log("Recovery mail was sent")
            dispatch({ type: MAIL_SENT, payload: null })
        })
})

export const resetPassword = (data) => (dispatch) => Promise.resolve().then(() => {
    return strapiResetPassword(data)
        .then(res => {
            // console.log(res)
            // console.log("Password was reset")
            dispatch({ type: PW_RESET, payload: null })
        })
        .catch(error => {
            // Handle error.
            // console.log('An error occurred:', error.response);
          });
})