import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './Hero.scss'
import CardText from '../../Molecules/CardText/CardText'
import ReactPlayer from 'react-player'
import ResponsiveImage from '../../Atoms/ResponsiveImage'

class Hero extends Component {

    state = {
        playing: true,
        loading: true,
    }

    play = () => {
        this.setState({playing: true})
    }

    pause = () => {
        this.setState({playing: false})
    }

    ready = () => {
        window.setTimeout(() => {
            this.setState({loading: false})
        }, 400)
    }

    width = document.documentElement.clientWidth
    // height = this.width * 9 / 16
    height = this.width / 2

    render() {
        return (
            <div
                className="hero hero-2024"
                key={this.props.item.slug}
                // onMouseEnter={this.play}
                // onMouseLeave={this.pause}
            >
                <ResponsiveImage
                    className={this.state.loading ? "poster active" : "poster"}
                    image={this.props.item.poster}
                    alt={this.props.item.location}
                />
                <ReactPlayer
                    className="player"
                    // playing={this.state.playing}
                    playing={true}
                    url={this.props.item.teaser}
                    config={{
                        vimeo: {
                            background: true,
                        }
                    }}
                    loop={true}
                    muted={true}
                    width={"100%"}
                    height={this.height}
                    playsinline={true}
                    onReady={this.ready}
                />
                <div className="text">
                    <Link
                        to={"/exhibitions/" + this.props.item.slug}
                        className="container"
                        onClick={this.props.onClick}
                    >
                        <h1 className={"claim " + this.props.item.overlay_color}>{this.props.claim}</h1>
                        <CardText
                            title={this.props.item.title}
                            lines={[this.props.item.location, this.props.item.time]}
                            style={this.props.item.overlay_color}    
                        />
                    </Link>
                </div>
            </div>
        )
    }

}

export default Hero