import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CookieConsent from "react-cookie-consent"
import Analytics from '../../components/Atoms/Analytics'

class CookieNotice extends Component {

    state = {
        active: true,
    }

    componentDidUpdate() {
        // console.log('cookies update')
    }

    render() {
        if (this.state.active) {
            return (
                <div role="button" className="modal-wrapper">
                    <div role="button" className="modal" >
                        <div className="logo black">
                            <h1>
                                Art Visit
                            </h1>
                        </div>
                        <CookieConsent
                            disableStyles
                            enableDeclineButton
                            buttonText="Accept all cookies"
                            declineButtonText="Accept essential"
                            flipButtons
                            onAccept={() => this.setState({ active: false })}
                            onDecline={() => this.setState({ active: false })}
                        >
                            This website requires the use of external video providers that may load essential cookies to display contents.
                            <br />
                            Other cookies optionally enable us to collect anonymised usage data.
                            <br /><br />
                            Please find more information in our <Link to="/page/privacy" style={{ textTransform: "none", borderBottom: "solid 2px" }}>privacy notice</Link>.
                            <br /><br /><br />
                        </CookieConsent>
                    </div>
                    <Analytics />
                </div>
            )
        } else return <Analytics />
    }
}

export default CookieNotice