import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom"
import { registerUser, setLayout, updateUser, persistLogin } from '../../lib/actions'

import Select from 'react-select'
import countryList from 'react-select-country-list'

import { Button } from '../../components/Atoms/Button'
import FormLink from '../../components/Atoms/FormLink'

import './Account.scss'

class Account extends Component {

    constructor(props) {
        super(props)

        this.countries = countryList().getData()

        this.state = {
            countries: this.countries,
            selectedCountry: "",
            user: {
                email: "",
                password: "",
                confirm: "",
                name: "",
                lastname: "",
                country: "",
            },
        }
    }

    componentDidMount() {
        this.props.setLayout({ header: "black", footer: "black", className: "" })
        this.props.persistLogin()
            .then(() => {
                this.setState({ user: this.props.user.userdata })
                this.state.user.country && this.setState({ selectedCountry: {
                        value: this.state.user.country,
                        label: countryList().getLabel(this.state.user.country)
                    }
                })
            })
    }

    handleSubmit = event => {
        event.preventDefault()
        this.props.updateUser(this.state.user)
            .then(() => {
                // this.props.userState === "loggedIn" && this.props.history.push(this.props.location.state.origin)
            })
    }

    handleChange = event => {
        const name = event.target.name
        const value = event.target.value
        this.setState(state => ({
            user: {
                ...state.user,
                [name]: value
            }
        }))
    }

    changeCountry = event => {
        this.setState({ selectedCountry: event })
        this.setState(state => ({
            user: {
                ...state.user,
                country: event.value
            }
        }))
    }


    render() {
        if (this.props.registrationState === "OK") {
            return (
                <div className="account container">
                    <div className="logo black">
                        <h1>
                            My Account
                        </h1>
                        <h2>
                            You're not logged in.
                        </h2>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="account">
                    <form>
                        <h1>
                            My Account
                        </h1>

                        <div className="input-group">
                            <label htmlFor="name">
                                Name:
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Name"
                                className=""
                                value={this.state.user.name}
                                onChange={this.handleChange}
                            />
                        </div>

                        <div className="input-group">
                            <label htmlFor="lastname">
                                Lastname:
                            </label>
                            <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                placeholder="Lastname"
                                className=""
                                value={this.state.user.lastname}
                                onChange={this.handleChange}
                            />
                        </div>

                        <div className="input-group">
                            <label htmlFor="email">
                                E-Mail:
                            </label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                placeholder="E-Mail"
                                value={this.state.user.email}
                                onChange={this.handleChange}
                            />
                        </div>

                        <div className="input-group">
                            <label htmlFor="password">
                                New Password:
                            </label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Password"
                                value={this.state.user.password}
                                onChange={this.handleChange}
                            />
                        </div>

                        <Select
                            options={this.state.countries}
                            value={this.state.selectedCountry}
                            onChange={this.changeCountry}
                            style={{ width: "100%" }}
                            styles={{
                                container: base => ({
                                    ...base,
                                    width: "100%",
                                    height: "36px",
                                    fontSize: "12px",
                                    textTransform: "uppercase",
                                    margin: "10px 0",
                                    zIndex: 5,
                                }),
                                control: base => ({
                                    ...base,
                                    border: "solid 1px black",
                                    height: "36px",
                                }),
                                input: base => ({ ...base, paddingLeft: "10px" }),
                                singleValue: base => ({ ...base, paddingLeft: "10px" }),
                                placeholder: base => ({ ...base, paddingLeft: "10px" }),
                            }}
                            placeholder={"Country"}
                            menuColor='black'
                            theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: 'lightgrey',
                                    primary50: 'grey',
                                    primary75: 'darkgrey',
                                    primary: 'black',
                                },
                            })}
                        />
                        <FormLink className="left full" to={{ pathname: '/ticket/delete', state: { modal: true, origin: this.props.location } }}>
                            Delete account?
                        </FormLink>
                        <Button black onClick={this.handleSubmit}>
                            Save
                    </Button>
                    </form>
                </div>
            )
        }


    }
}

const mapStateToProps = (state) => {
    return {
        globals: state.globals,
        layout: state.layout,
        user: state.user,
        userState: state.userState
    }
}

export default withRouter(connect(
    mapStateToProps,
    { registerUser, setLayout, updateUser, persistLogin }
)(Account))