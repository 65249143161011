import axios from 'axios'

import store from './store'

const API_URL = process.env.REACT_APP_API_URL + "/"

const authHeader = () => {

    let token = localStorage.artvisit_jwt
    if (!token) {
        token = store.getState().user.jwt
    }

    if (token) {
        return { Authorization: `Bearer ${token}` }
    } else {
        return {};
    }
}

export const getSingle = (type) => {
    return axios.get(API_URL + type, {
        headers: authHeader()
    })
}

export const getCollection = (collection, filters = {}) => {
    // console.log(API_URL)
    let str = ""
    Object.entries(filters).forEach((value, index) => {
        index === 0
            ? str += "?"
            : str += "&"
        str += value[0]
        str += "="
        str += value[1]
    })
    return axios.get(API_URL + collection + str, {
        headers: authHeader()
    })
}

export const strapiRegister = (data) => {
    // console.log("strapi register")
    // console.log(data)
    return axios.post(`${API_URL}auth/local/register`, data)
}

export const strapiConfirmEmail = (email) => {
    // console.log("strapi confirm email")
    // console.log(email)
    return axios.post(`${API_URL}auth/send-email-confirmation`, {
        email: email,
    })
}

export const strapiRecoverPassword = (email) => {
    return axios.post(`${API_URL}auth/forgot-password`, {
        email: email,
    })
}

export const strapiResetPassword = (data) => {
    return axios.post(`${API_URL}auth/reset-password`, data)
}

export const strapiLogin = (userdata) => {
    return axios.post(`${API_URL}auth/local`, userdata)
}

export const getMe = () => {
    return axios.get(`${API_URL}users/me`, {
        headers: authHeader()
    })
}

export const strapiUpdate = (user) => {
    return axios.put(`${API_URL}users/${user.id}`, user, {
        headers: authHeader()
    })
}

export const strapiDeleteUser = (user) => {
    return axios.delete(`${API_URL}users/${user.id}`, {
        headers: authHeader()
    })
}