import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadSingle, setLayout } from '../../lib/actions'
import ReactMarkdown from 'react-markdown'

import './Contact.scss'
import { Helmet } from 'react-helmet-async'

class Contact extends Component {

    componentDidMount() {
        if (this.props.item.type !== "contact") this.props.loadSingle("contact")
        this.props.setLayout({ header: "black", footer: "black", className: "" })
    }

    render() {
        return (
            <div className="contact">
                <h1>
                    {this.props.item?.title}
                </h1>
                <ReactMarkdown source={this.props.item?.text} className="contents" />
                <Helmet>
                    <title>{this.props.globals?.title + " - " + this.props.item?.title}</title>
                    <meta property="og:title" content={this.props.globals?.title + " - " + this.props.item?.title} />
                </Helmet>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        item: state.item,
        user: state.user,
        userState: state.userState,
        globals: state.globals
    }
}

export default connect(
    mapStateToProps,
    { loadSingle, setLayout }
)(Contact);