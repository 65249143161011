import styled from 'styled-components'
import React, { Component } from 'react';

import mouse from '../../assets/img/mouse.svg'
import keys from '../../assets/img/keys.svg'
import trackpad from '../../assets/img/trackpad.svg'

const MyDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    opacity: 1;
    display: block;
    filter: drop-shadow(0px 0px 5px black);
    box-shadow: inset 0 -120px 160px -100px black;
    animation: overlay-fadeout 400ms forwards ease;
    animation-delay: 8000ms;
    color: white;

    & > .overlay-inner {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        bottom: 0;
        top: auto;
        transform: none;
        width: 60%;
        position: absolute;
        left: 20%;

        & > * {
            width: 33%;
        }

    }

    @keyframes overlay-fadeout {
        0% {
            display: block;
            opacity: 1;
            z-index: 10;
        }
        95% {
            display: block;
            opacity: 0;
            z-index: 10;
        }
        100% {
            display: none;
            opacity: 0;
            z-index: -1;
        }
    }
`

export default class ControlsOverlay extends Component {
    render() {
        const { children } = this.props
        return (
            <MyDiv className={"controls-overlay " + this.props.className}>
                    {
                        children
                        ?  
                            children
                        :
                            <div className="overlay-inner">
                                <div className="keys">
                                    <img src={keys} alt="keys" />
                                </div>
                                <div className="trackpad">
                                    <img src={trackpad}  alt="keys" />
                                </div>
                                <div className="mouse">
                                    <img src={mouse}  alt="keys" />
                                </div>
                            </div>
                    }
            </MyDiv>
        )
    }
}