import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import './Modal.scss'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import Logout from './Logout'
import Delete from './Delete'
import Forgot from './Forgot'
import Reset from './Reset'
import Cookies from './Cookies'

class Modal extends Component {

    render() {
        return (
            <div role="button" className="modal-wrapper" onClick={() => this.props.history.push(this.props.location.state.origin)} >
                <div role="button" className="modal" onClick={e => e.stopPropagation()} >
                    {
                        {
                            'login': <LoginForm />,
                            'register': <RegisterForm />,
                            'logout': <Logout />,
                            'delete': <Delete />,
                            'forgot': <Forgot />,
                            'reset': <Reset />,
                            'cookies': <Cookies />
                        }[this.props.match.params.type]
                    }
                </div>
            </div>
        )

    }
}

export default withRouter(Modal);