import styled from 'styled-components'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const MyDiv = styled.div`
    width: 50%;
    text-align: right;
    font-size: var(--font-xs);
    text-transform: uppercase;
    padding: var(--spacing-xs) 0;
    & > a {
        padding-top: var(--spacing-xs);
        letter-spacing: 0.5px;
        user-select: none;
        cursor: pointer;
        &:hover {
            border-bottom: solid black 1px;
        }
    }
    &.left {
        text-align: left;
    }
    &.full {
        width: 100%;
        font-size: var(--font-s);
    }
`

export default class FormLink extends Component {
    render() {
        const { children } = this.props
        return (
            <MyDiv className={"form-link half " + this.props.className}>
                <Link to={this.props.to}>
                    {children}
                </Link>
            </MyDiv>
        )
    }
}