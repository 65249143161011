import styled from 'styled-components'

export const Button = styled.button`
    margin: var(--spacing-s) auto;
    margin-bottom: 0;
    padding: var(--spacing-xxs) var(--spacing-s);
    border: 2px solid black;
    background-color: ${props => props.black ? "black" : "white"};
    color: ${props => props.white ? "black" : "white"};
    -webkit-appearance: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: 200ms 100ms;
    &:active {
        background-color: ${props => props.white ? "black" : "white"};
        color: ${props => props.black ? "black" : "white"};
        transition: 0ms;
    }
`