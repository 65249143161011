import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { mobileVendor, isMobileOnly } from "react-device-detect";
import './VrPlayer.scss'
import LazyImage from '../../Atoms/LazyImage'
import { Link, withRouter } from 'react-router-dom'
import ControlsOverlay from '../../Atoms/ControlsOverlay'

class VrPlayer extends Component {

  width = window.innerWidth
  height = this.width / 2

  getId(url) {
    const myregexp = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/gi;
    return url?.split(myregexp)[1];
  }

  render() {

    if (this.props.playing) {
      return (
        <div className="vr-player">
        {
          mobileVendor === "Apple" && isMobileOnly && window.open(`https://www.youtube.com/embed/${this.getId(this.props.item.video)}?vq=hd1440&autoplay=1&modestbranding=1&rel=0&autohide=1&showinfo=0&controls=1&playsinline=1&webkit-playsinline=1&color=white`, '_blank')
        }
          <ReactPlayer
            className="player"
            url={this.props.item.video}
            config={{
              youtube: {
                playerVars: {
                  playsinline: 1,
                  autoplay: 1,
                  controls: 2,
                  modestbranding: 1,
                  color: "white",
                }
              }
            }}
            loop={false}
            muted={false}
            width={this.width}
            height={this.height}
            style={{
            }}
            onReady={() => {
            }}
          />
          <ControlsOverlay />
        </div>
      )
    } else {
      return (
        <div className="vr-player">
          {
            this.props.loggedIn
              ?
              <Link
                to="#"
                className="overlay"
                onClick={/* this.props.play(true) */()=>{this.props.play(true)}}
              >
                <LazyImage
                  className="poster"
                  image={this.props.item.poster}
                  alt={this.props.item.location}
                />
                <div className="cta">
                  <h3 className={this.props.item.overlay_color}>Watch now</h3>
                </div>
              </Link>

              :
              <Link
                className="overlay"
                to={{
                  pathname: '/ticket/login',
                  state: { modal: true, origin: this.props.location }
                }}
              >
                <LazyImage
                  className="poster"
                  image={this.props.item.poster}
                  alt={this.props.item.location}
                />
                <div className="cta">
                  <h3 className={this.props.item.overlay_color}>Watch now</h3>
                </div>
              </Link>
          }
        </div>
      )
    }
  }
}

export default withRouter(VrPlayer)