import {
    ITEM_LOADED,
    COLLECTION_LOADED,
    GLOBALS_LOADED,
    REGISTRATION_FAIL,
    REGISTRATION_SUCC,
    LOGIN,
    LOGIN_FAIL,
    LOGOUT,
    SET_LAYOUT,
    UPDATE_USER,
    PLAY,
    PW_RESET,
    MAIL_SENT
} from "./action-types"

const initialState = {
    item: {},
    collection: [],
    globals: {},
    registrationState: "",
    userState: "",
    user: {
        jwt: "",
        userdata: {},
    },
    layout: {
        header: "white",
        footer: "black",
        classname: "",
    },
    playing: false,
};


export default (state = initialState, action) => {
    switch (action.type) {

        case ITEM_LOADED:
            return Object.assign({}, state, {
                item: action.payload,
            })

        case COLLECTION_LOADED:
            return Object.assign({}, state, {
                collection: action.payload,
            })

        case GLOBALS_LOADED:
            return Object.assign({}, state, {
                globals: action.payload,
            })

        case REGISTRATION_SUCC:
            // console.log("register reducer")
            // console.log(action.payload)
            return Object.assign({}, state, {
                registrationState: action.payload,
            })

        case REGISTRATION_FAIL:
            return Object.assign({}, state, {
                registrationState: action.payload,
            })

        case LOGIN:
            return Object.assign({}, state, {
                user: action.payload,
                userState: "loggedIn"
            })

        case LOGIN_FAIL:
            return Object.assign({}, state, {
                userState: action.payload
            })

        case LOGOUT:
            return Object.assign({}, state, {
                userState: "",
                user: {}
            })

        case SET_LAYOUT:
            return Object.assign({}, state, {
                layout: action.payload
            })

        case UPDATE_USER:
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    userdata: {
                        ...state.user.userdata,
                        [action.payload.name]: action.payload.target
                    }
                }
            })

        case PLAY:
            return Object.assign({}, state, {
                playing: action.payload
            })

        case PW_RESET:
            return Object.assign({}, state, {
                userState: "pwReset"
            })

        case MAIL_SENT:
            return Object.assign({}, state, {
                userState: "mailSent"
            })

        default:
            return state
    }
};