import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { recoverPassword } from '../../lib/actions'

import { Button } from '../../components/Atoms/Button'
import { withRouter } from 'react-router-dom'

class Forgot extends Component {

    state = {
        email: "",
    }

    handleSubmit = event => {
        event.preventDefault()
        this.props.recoverPassword(this.state.email)
            .then(() => {
                // console.log("forgot recorver")
            })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {})
    }


    render() {
        if (this.props.userState === "loggedIn") {
            return (
                <Fragment>
                    <div className="logo black">
                        <h1>
                            Art Visit
                        </h1>
                    </div>
                    <h2>You're already logged in.</h2>
                </Fragment>
            )
        } else if (this.props.userState === "mailSent") {
            return (
                <Fragment>
                    <div className="logo black">
                        <h1>
                            Art Visit
                        </h1>
                    </div>
                    <h2>
                        We have sent you an E-Mail with further instructions on how to reset your password. Please check your inbox.
                    </h2>
                </Fragment>
            )
        } else {
            return (
                <form>
                    <div className="logo black">
                        <h1>
                            Art Visit
                        </h1>
                    </div>
                    <h3>Please enter your E-Mail to recover your password:</h3>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="E-Mail"
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                    <Button black type="submit" onClick={this.handleSubmit}>
                        Submit
                    </Button>
                </form>
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        globals: state.globals,
        userState: state.userState,
        user: state.user,
        item: state.item
    }
}


export default withRouter(connect(
    mapStateToProps,
    { recoverPassword }
)(Forgot))