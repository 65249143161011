import React from 'react'
import { Helmet } from 'react-helmet-async'
import { getCookieConsentValue } from "react-cookie-consent"

export const GA_TRACKING_ID = 'G-JN0CWLRTQB'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
  window.gtag('config', GA_TRACKING_ID, {
    page_location: url
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  })
}

export default () => {

  if (getCookieConsentValue() && process.env.NODE_ENV !== 'development') {
    return (
      <>
        <Helmet>
          {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-JN0CWLRTQB"></script>
        </Helmet>
        <Helmet
          script={[{
            type: 'text/javascript',
            innerHTML: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
  
            gtag('config', 'G-JN0CWLRTQB');`
          }]}
        />
      </>
    )
  } else return <></>
}