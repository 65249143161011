export const ITEM_LOADED = "ITEM_LOADED"
export const COLLECTION_LOADED = "COLLECTION_LOADED"
export const GLOBALS_LOADED = "GLOBALS_LOADED"
export const REGISTRATION_SUCC = "REGISTRATION_SUCC"
export const REGISTRATION_FAIL = "REGISTRATION_FAIL"
export const LOGIN = "LOGIN"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"
export const SET_LAYOUT = "SET_LAYOUT"
export const UPDATE_USER = "UPDATE_USER"
export const PLAY = "PLAY"
export const PW_RESET = "PW_RESET"
export const MAIL_SENT = "MAIL_SENT"