import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { resetPassword } from '../../lib/actions'

import qs from 'qs'

import { Button } from '../../components/Atoms/Button'
import { withRouter, Link } from 'react-router-dom'

class Reset extends Component {

    state = {
        code: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code,
        password: "",
        passwordConfirmation: ""
    }

    handleSubmit = event => {
        event.preventDefault()
        this.props.resetPassword(this.state)
            .then(() => {
                // console.log("reset pw")
            })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => { })
    }


    render() {

        if (this.props.userState === "loggedIn") {
            return (
                <Fragment>
                    <div className="logo black">
                        <h1>
                            Art Visit
                        </h1>
                    </div>
                    <h2>You're already logged in.</h2>
                </Fragment>
            )
        } else if (this.props.userState === "pwReset") {
            return (
                <Fragment>
                    <div className="logo black">
                        <h1>
                            Art Visit
                        </h1>
                    </div>
                    <h2>
                        Your password was reset successfully. You can now
                        <Link to={{ pathname: '/ticket/login', state: { modal: true, origin: this.props.location.state.origin } }}>
                            log in
                        </Link> log in
                    </h2>
                </Fragment>
            )
        } else {
            return (
                <form>
                    <div className="logo black">
                        <h1>
                            Art Visit
                        </h1>
                    </div>
                    <h3>Please enter your new Password:</h3>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="New Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                    />
                    <input
                        type="password"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        placeholder="Confirm"
                        value={this.state.passwordConfirmation}
                        onChange={this.handleChange}
                    />
                    <Button black type="submit" onClick={this.handleSubmit}>
                        Submit
                    </Button>
                </form>
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        globals: state.globals,
        userState: state.userState,
        user: state.user,
        item: state.item
    }
}


export default withRouter(connect(
    mapStateToProps,
    { resetPassword }
)(Reset))