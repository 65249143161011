import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadGlobals, persistLogin, logoutUser } from './lib/actions'
import { Route, Switch, withRouter } from "react-router-dom"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { getCookieConsentValue } from "react-cookie-consent"
import Cookies from './views/Modal/Cookies'
import Exhibitions from './views/Exhibitions/Exhibitions'
import ExhibitionItem from './views/Items/ExhibitionItem'
import Modal from './views/Modal/Modal'
import Layout from './views/Layout'
import TextPage from './views/TextPage/TextPage'
import Account from './views/Account/Account'
import Contact from './views/Contact/Contact'
import Analytics from './components/Atoms/Analytics'

class App extends Component {

  constructor(props) {
    super(props);
    this.prevLoc = this.props.location;
  }

  componentWillUpdate() {
    const { location } = this.props;
    if (!(location.state && location.state.modal)) {
      this.prevLoc = this.props.location;
    }
  }

  componentDidMount() {
    this.props.persistLogin().then(() => {
      this.props.loadGlobals()
    })
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
      && this.props.location.pathname.indexOf("ticket") < 0
      && !prevProps.location.state
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { location } = this.props;

    let background = location.state && location.state.origin

    if (location.state === undefined && location.pathname.includes("ticket")) {
      background = true
    }


    return (
      <HelmetProvider>
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/htb3lju.css" />
        </Helmet>
        <Analytics />

        <Layout>
          <Switch location={background || location}>
            <Route exact path="/" component={Exhibitions} />
            <Route exact path="/exhibitions" component={Exhibitions} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/contact" component={Contact} />
            <Route path="/exhibitions/:slug" component={ExhibitionItem} />
            <Route path="/page/:slug" component={TextPage} />
            <Route exact path="/ticket/:type" component={Exhibitions} />


            <Route path="*" component={Exhibitions} />
          </Switch>

          {background && <Route exact path="/ticket/:type" children={<Modal />} />}
          {!getCookieConsentValue() && <Cookies />}
        </Layout>

      </HelmetProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    collection: state.collection,
    globals: state.globals,
    user: state.user
  }
}

export default withRouter(connect(
  mapStateToProps,
  { persistLogin, loadGlobals, logoutUser }
)(App));