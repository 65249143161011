import React, { Component } from 'react'

import './CardText.scss'

class CardText extends Component {

    render() {
        return (
            <div className={"card-text " + this.props.style}>
                <h2>{this.props.title}</h2>
                {this.props.lines.map((line, index )=> (
                    <div key={index}>{line}</div>
                ))}
            </div>
        )
    }

}

export default CardText