import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { logoutUser } from '../../lib/actions'
import { withRouter } from 'react-router-dom'

class Logout extends Component {

    componentDidMount() {
        this.props.logoutUser()
              .then(() => {this.props.history.goBack()})
    }

    render() {
            return (
                <Fragment>
                    <div className="logo black">
                        <h1>
                            Art Visit
                        </h1>
                    </div>
                    <h2>You're being logged out.</h2>
                </Fragment>
            )

    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default withRouter(connect(
    mapStateToProps,
    { logoutUser }
)(Logout))