import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

class Layout extends Component {

    state = {
        menu: false,
    }

    toggleMenu = () => {
        this.setState({ menu: this.state.menu ? false : true })
    }

    MenuDesktop = () => {
        return (
            <nav className="half desktop">
                <Link to={"/exhibitions"}>
                    Exhibitions
                </Link>

                {/* search is currently deactivated */}
                {/* <span className="search">
                    <input
                        type="search"
                        id="site-search"
                        name="search"
                        aria-label="Search through site content"
                    />
                </span> */}
                <a href={this.props.globals.instagram} className="instagram" target="_blank" rel="noopener noreferrer">
                </a>

                <span role="button" className={this.state.menu ? "burger active" : "burger"} onClick={this.toggleMenu}>
                </span>
                <div className={this.state.menu ? "menu active" : "menu"}>
                    {/* {
                        this.props.userState !== "loggedIn"
                            ?
                            <Link
                                to={{ pathname: '/ticket/login', state: { modal: true, origin: this.props.location } }}
                                onClick={this.toggleMenu}    
                            >
                                Login
                            </Link>
                            :
                            <Fragment>
                                <Link to={"/account"}  onClick={this.toggleMenu}>
                                    My Account
                                </Link>
                                <br />
                                <Link
                                    to={{ pathname: '/ticket/logout', state: { modal: true, origin: this.props.location } }}
                                    onClick={this.toggleMenu}
                                >
                                    Logout
                                </Link>
                            </Fragment>
                    } */}
                    <Link
                        to={{ pathname: '/page/about' }}
                        onClick={this.toggleMenu}
                    >
                        About
                    </Link>
                </div>
            </nav>
        )
    }

    MenuMobile = () => {
        return (
            <nav className={this.state.menu ? "half mobile active" : "half mobile"}>
                <span role="button" className={this.state.menu ? "burger active" : "burger"} onClick={this.toggleMenu}>
                </span>
                <div className={this.state.menu ? "menu active" : "menu"}>
                    <Link
                        to={{ pathname: '/page/about' }}
                        onClick={this.toggleMenu}
                    >
                        About
                    </Link>
                    <br />
                    <Link to={"/exhibitions"} onClick={this.toggleMenu}>
                        Exhibitions
                    </Link>
                    <br />
                    <a href={this.props.globals.instagram} className="instagram" target="_blank" rel="noopener noreferrer" onClick={this.toggleMenu}>
                        Instagram
                    </a>

                    {/* deactivate user related menu items for now */}
                    {/* <br />
                    {
                        this.props.userState !== "loggedIn"
                            ?
                            <Link
                                to={{ pathname: '/ticket/login', state: { modal: true, origin: this.props.location } }}
                                onClick={this.toggleMenu}
                            >
                                Login
                            </Link>
                            :
                            <Fragment>
                                <Link to={"/account"}  onClick={this.toggleMenu}>
                                My Account
                                </Link>
                                <br />
                                <Link
                                    to={{ pathname: '/ticket/logout', state: { modal: true, origin: this.props.location } }}
                                    onClick={this.toggleMenu}
                                >
                                    Logout
                                </Link>
                            </Fragment>
                    } */}
                </div>
            </nav>
        )
    }

    render() {
        const { children } = this.props
        return (
            <div className={this.state.menu ? "layout menu-open" : "layout"}>
                <header className={this.props.layout.header + " container"}>
                    <div className="row">
                        <h1 className="logo half"
                            onClick={() => this.props.history.push("/")} >
                            Art Visit
                        </h1>
                        {
                            window.innerWidth <= 980
                                ? <this.MenuMobile />
                                : <this.MenuDesktop />
                        }
                    </div>
                </header>
                <main className={this.props.className}>
                    {children}
                </main>
                <footer className={this.props.layout.footer + " container"}>
                    <nav className="row footer-menu">
                        <ul className="left">
                            <li>
                                <Link to="/page/privacy">
                                    privacy
                                </Link>
                            </li>
                            <li>
                                <Link to="/page/imprint">
                                    imprint
                                </Link>
                            </li>
                        </ul>
                        <h1 className="logo small"
                            onClick={() => this.props.history.push("/")} >
                            Art Visit
                        </h1>
                        <ul className="right">
                            <li>
                                <a href={this.props.globals.instagram} className="instagram" target="_blank" rel="noopener noreferrer">
                                </a>
                            </li>
                            <li>
                                <Link to="/contact">
                                    contact
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </footer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        userState: state.userState,
        layout: state.layout,
        globals: state.globals
    }
}

export default withRouter(connect(
    mapStateToProps,
    {}
)(Layout))