import styled from 'styled-components'
import React, { Component, Fragment } from 'react';

const MyCheckbox = styled.input`
    width: 100%;
    margin: var(--spacing-xs) 0;
    padding: var(--spacing-xs) var(--spacing-s);
    text-transform: uppercase;
    border: solid black 1px;
    color: black;
    position: absolute;
    opacity: 0;
    &:checked + label.checkbox::before {
        content: "✕";
    }
`

const MyLabel = styled.label`
    display: inline-flex;
    width: 50%;
    font-size: var(--font-xs);
    text-transform: uppercase;
    padding: var(--spacing-xs) 0;
    cursor: pointer;
    .checkbox-label {
        width: 80%;
        padding: 0 var(--spacing-xs);
        text-align: left;
        span {
            padding-top: var(--spacing-xs);
            letter-spacing: 0.5px;
            user-select: none;
            cursor: pointer;
        }
    }
    &::before {
        content: "";
        display: inline-block;
        height: var(--font-m);
        width: calc(var(--font-m) - 1px);
        margin-top: -5px;
        border: 1px solid black;
        padding-left: 1px;
        font-size: 18px;
        line-height: 15px;
    }
`

export default class Checkbox extends Component {

    state = {
        name: this.props.id,
        value: ""
    }

    handleChange = event => {
        this.setState({
            value: event.target.checked
        }, () => {this.props.onChange({target: this.state})})
    }

    render() {
        const { children } = this.props
        return (
            <Fragment>
                <MyCheckbox
                    type="checkbox"
                    name={this.props.id}
                    id={this.props.id}
                    checked={this.state.checked}
                    onChange={this.handleChange}
                />
                <MyLabel htmlFor={this.props.id} className="checkbox">
                    <div className="checkbox-label">
                        <span>
                            {children}
                        </span>
                    </div>
                </MyLabel>
            </Fragment>
        )
    }
}