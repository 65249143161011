import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import ResponsiveImage from '../../Atoms/ResponsiveImage'

import './TextImage.scss'

class TextImage extends Component {

    render() {
        console.log(this.props)
        return (
            <div className="text-image-container">
                {this.props.content?.map((item, index) => (
                    <div key={index} className={`text-image-layout ${item.left_orientation ? 'reverse' : 'forward'}`}>
                        <ReactMarkdown source={item.Text} className="contents" />
                        <ResponsiveImage image={item.Image} alt={item.Image.caption} className="responsive-image" />
                    </div>
                ))}
            </div>
        )
    }

}

export default TextImage